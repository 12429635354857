import { AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import * as isToday from 'date-fns/is_today';
import { MarketingService } from '@app/core/services/marketing.service';
var ConvertionService = /** @class */ (function () {
    function ConvertionService(app_config, platformId, sessionStorageService, document, marketingService) {
        this.app_config = app_config;
        this.platformId = platformId;
        this.sessionStorageService = sessionStorageService;
        this.document = document;
        this.marketingService = marketingService;
    }
    ConvertionService.prototype.reportSubscriptionSale = function (data) {
        var subscription = data.subscription, payment = data.payment, user = data.user, plan = data.plan, coupon = data.coupon;
        this.reportTransactionComplete(user, subscription, coupon, payment, plan);
        this.reportIre(subscription, user, coupon, plan);
        this.reportUetq(payment);
        this.assuranceConversion();
        // Track BD with the 3for30 promo plan
        if (plan._id === '5dc06f5efd62286c229df74e') {
            this.reportBDsales({ payment: payment, plan: plan });
            this.reportBDSales2({ payment: payment, plan: plan });
        }
    };
    ConvertionService.prototype.reportOTBSale = function (_a) {
        var user = _a.user, payment = _a.payment, shipment = _a.shipment, box = _a.box;
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var shippingCosts = box.shippingCosts;
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? shipment._id : "TEST_" + shipment._id,
                'transactionTotal': payment.amount,
                'transactionTax': payment.taxes,
                'transactionShipping': shippingCosts * payment.shipments.length,
                'transactionType': payment.transaction,
                'transactionProducts': [
                    {
                        'name': payment.transaction,
                        'sku': payment.transaction,
                        'price': box.price,
                        'quantity': payment.shipments.length
                    }
                ],
                'customerEmail': user.email,
            });
            this.assuranceConversion();
        }
    };
    ConvertionService.prototype.reportShipmentSale = function (_a) {
        var user = _a.user, subscription = _a.subscription, coupon = _a.coupon, payment = _a.payment, plan = _a.plan;
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var utmData = this.sessionStorageService.retrieve('utmData');
            var affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
            var shippingCosts = plan.shippingCosts;
            console.log('Affiliate Ref', affiliateRef);
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? subscription._id : "TEST_" + subscription._id,
                'transactionTotal': payment.amount,
                'transactionTax': payment.taxes,
                'transactionShipping': shippingCosts * payment.shipments.length,
                'transactionType': payment.transaction,
                'transactionProducts': [
                    {
                        'name': payment.transaction,
                        'sku': payment.transaction,
                        'price': plan.price,
                        'quantity': payment.shipments.length
                    }
                ],
                'customerEmail': user.email,
                'sourceParam': utmData && utmData.utm_source,
                'sourceAffiliateRef': affiliateRef,
                'coupon': coupon && coupon.code.toLowerCase()
            });
            this.assuranceConversion();
        }
    };
    ConvertionService.prototype.reportBDsales = function (_a) {
        var payment = _a.payment, plan = _a.plan;
        var script = this.document.createElement('iframe');
        script.width = '1px';
        script.height = '1px';
        script.setAttribute('nid', '104');
        script.setAttribute('oid', '15');
        script.setAttribute('transaction_id', '');
        script.setAttribute('amount', (plan.price).toString());
        script.order = {
            oid: payment.subscription,
            amt: plan.price,
            bs: '',
            bc: '',
            cc: '',
            items: []
        };
        script.src = 'https://bradsdeals.servtrk.com/?nid=104'
            + script.getAttribute('nid')
            + '&oid=15'
            + script.getAttribute('oid')
            + '&transaction_id='
            + script.getAttribute('transaction_id') + '';
        if (parseInt(script.getAttribute('amount'), 10) > 0) {
            script.src += '&amount=' + script.getAttribute('amount');
        }
        if (script.order) {
            script.src += '&order=' + encodeURIComponent(JSON.stringify(script.order));
        }
        this.document.body.appendChild(script);
    };
    ConvertionService.prototype.reportBDSales2 = function (_a) {
        var payment = _a.payment, plan = _a.plan;
        var script = this.document.createElement('iframe');
        script.width = '1px';
        script.height = '1px';
        script.setAttribute('nid', '104');
        script.setAttribute('oid', '17');
        script.setAttribute('transaction_id', '');
        script.setAttribute('amount', (plan.price).toString());
        script.order = {
            oid: payment.subscription,
            amt: plan.price,
            bs: '',
            bc: '',
            cc: '',
            items: []
        };
        script.src = 'https://www.ttysetrk.com/?nid=104&oid=17&transaction_id='
            + script.getAttribute('transaction_id')
            + '&amount='
            + script.getAttribute('amount')
            + '';
        if (parseInt(script.getAttribute('amount'), 10) > 0) {
            script.src += '&amount=' + script.getAttribute('amount');
        }
        if (script.order) {
            script.src += '&order=' + encodeURIComponent(JSON.stringify(script.order));
        }
        this.document.body.appendChild(script);
    };
    ConvertionService.prototype.assuranceConversion = function () {
        try {
            if (this.app_config.production) {
                var script_1 = document.createElement('script');
                script_1.src = 'https://assurance.mimtid.com/adstart/t.js?c=4EF238BDE8-conversion';
                script_1.type = 'text/javascript';
                new Promise(function (resolve, reject) {
                    script_1.onload = function () {
                        resolve();
                    };
                    script_1.onerror = function (error) {
                        reject(error);
                    };
                    document.getElementsByTagName('body')[0].appendChild(script_1);
                });
            }
        }
        catch (error) {
            console.error('Error: ', error);
        }
    };
    ConvertionService.prototype.reportTransactionComplete = function (user, subscription, coupon, payment, plan) {
        var _this = this;
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var utmData_1 = this.sessionStorageService.retrieve('utmData');
            var affiliateRef_1 = this.sessionStorageService.retrieve('affiliateRef');
            var shippingCosts_1 = plan.shippingCosts;
            console.log('Affiliate Ref', affiliateRef_1);
            this.marketingService.reportPurchase({
                value: payment && payment.amount || 0,
                planName: plan.name,
                id: this.app_config.production && this.app_config.name === 'production'
                    ? subscription._id : "TEST_" + subscription._id,
            })
                .subscribe(function (response) {
                window.dataLayer.push({
                    'event': 'transactionComplete',
                    eventID: response.eventId,
                    'transactionId': _this.app_config.production && _this.app_config.name === 'production'
                        ? subscription._id : "TEST_" + subscription._id,
                    'transactionTotal': payment && payment.amount || 0,
                    'transactionTax': payment && payment.taxes || 0,
                    'transactionShipping': subscription.isPrepay ? shippingCosts_1 * plan.term : shippingCosts_1 * plan.shipmentsAtCheckout,
                    'transactionType': plan.type,
                    'transactionProducts': [
                        {
                            'name': plan.title,
                            'sku': plan.name,
                            'price': plan.price,
                            'quantity': subscription.isPrepay ? plan.term : 1
                        }
                    ],
                    'customerEmail': user.email,
                    'sourceParam': utmData_1 && utmData_1.utm_source,
                    'sourceAffiliateRef': affiliateRef_1,
                    'coupon': coupon && coupon.code.toLowerCase()
                });
            });
        }
    };
    ConvertionService.prototype.reportIre = function (subscription, user, coupon, plan) {
        if (isPlatformBrowser(this.platformId) && window.ire) {
            window['ire']('identify', {
                customerId: user._id,
                customerEmail: user.email
            });
            window['ire']('trackConversion', 15273, {
                orderId: subscription._id,
                customerId: user._id,
                customerStatus: isToday(user.createdAt) ? 'new' : 'returning',
                orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
                orderDiscount: coupon ? parseInt(coupon.amount, 10) : 0,
                currencyCode: 'USD',
                items: [
                    {
                        subTotal: plan.price,
                        category: plan.type.toLowerCase(),
                        sku: plan.name,
                        quantity: 1
                    }
                ]
            });
        }
    };
    ConvertionService.prototype.reportUetq = function (payment) {
        if (isPlatformBrowser(this.platformId) && window.uetq && window.uetq.push) {
            window['uetq'].push('event', 'checkout', {
                revenue_value: payment && payment.amount || 0,
                currency: 'USD'
            });
        }
    };
    return ConvertionService;
}());
export { ConvertionService };
