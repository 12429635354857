import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
// services
import { AuthService } from './../../auth/services/auth.service';
var UserService = /** @class */ (function () {
    function UserService(http, authService, app_config) {
        this.http = http;
        this.authService = authService;
        this.app_config = app_config;
    }
    UserService.prototype.updateAccount = function (data) {
        var userId = this.authService.tokenPayload._id;
        var request = new HttpRequest('PUT', this.app_config.api_uri + "/users/" + userId + "/account", data, { reportProgress: true });
        return this.http
            .request(request);
    };
    UserService.prototype.updatePassword = function (data) {
        var userId = this.authService.tokenPayload._id;
        return this.http
            .put(this.app_config.api_uri + "/users/" + userId + "/change-password", tslib_1.__assign({}, data));
    };
    UserService.prototype.updateName = function (_a) {
        var firstName = _a.firstName, lastName = _a.lastName, _id = _a._id;
        var userId = this.authService.tokenPayload._id;
        return this.http
            .put(this.app_config.api_uri + "/users/" + userId + "/update-name", { firstName: firstName, lastName: lastName, _id: _id })
            .pipe(map(function (result) { return result; }));
    };
    UserService.prototype.addNewPaymentMethod = function (_a) {
        var nonce = _a.nonce, cardholderName = _a.cardholderName;
        var userId = this.authService.tokenPayload._id;
        return this.http
            .post(this.app_config.api_uri + "/users/" + userId + "/add-payment-method", { nonce: nonce, cardholderName: cardholderName })
            .pipe(map(function (result) { return result.paymentMethod; }));
    };
    UserService.prototype.updatePaymentMethod = function (_a) {
        var paymentMethodId = _a.paymentMethodId, nonce = _a.nonce, cardholderName = _a.cardholderName, _b = _a.paymentProcessor, paymentProcessor = _b === void 0 ? 'braintree' : _b;
        var userId = this.authService.tokenPayload._id;
        return this.http
            .put(this.app_config.api_uri + "/users/" + userId + "/update-payment-method", { paymentMethodId: paymentMethodId, nonce: nonce, cardholderName: cardholderName, paymentProcessor: paymentProcessor });
    };
    UserService.prototype.updatePaymentMethodAndRebill = function (_a) {
        var paymentMethodId = _a.paymentMethodId, nonce = _a.nonce, cardholderName = _a.cardholderName, paymentProcessor = _a.paymentProcessor;
        var userId = this.authService.tokenPayload._id;
        return this.http
            .put(this.app_config.api_uri + "/users/" + userId + "/update-payment-method-and-rebill", { paymentMethodId: paymentMethodId, nonce: nonce, cardholderName: cardholderName, paymentProcessor: paymentProcessor });
    };
    UserService.prototype.buyShipment = function (_a) {
        var boxId = _a.boxId, address = _a.address, gift = _a.gift, paymentProcessor = _a.paymentProcessor;
        var userId = this.authService.tokenPayload._id;
        return this.http
            .post(this.app_config.api_uri + "/users/" + userId + "/buy-shipment", { boxId: boxId, address: address, gift: gift, paymentProcessor: paymentProcessor });
    };
    UserService.prototype.buyShipments = function (data) {
        var userId = this.authService.tokenPayload._id;
        return this.http
            .post(this.app_config.api_uri + "/users/" + userId + "/buy-shipments", {
            boxes: data.boxes,
            address: data.address,
            gift: data.gift,
            paymentProcessor: data.paymentProcessor
        });
    };
    UserService.prototype.getUserSubscription = function (id) {
        var userId = this.authService.tokenPayload._id;
        return this.http
            .get(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + id);
    };
    UserService.prototype.cancelSubscription = function () {
        var userId = this.authService.tokenPayload._id;
        return this.http
            .delete(this.app_config.api_uri + "/users" + userId + "/subscription");
    };
    UserService.prototype.setUserMonthBox = function (id) {
        var userId = this.authService.tokenPayload._id;
        return this.http
            .put(this.app_config.api_uri + "/users/" + userId + "/set-month-box", {
            box: id,
        });
    };
    return UserService;
}());
export { UserService };
