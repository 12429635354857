import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromPaymentMethodActions from './../actions/payment-methods.actions';
// models
import { PaymentMethod } from '@app/core/models/payment-method';
// services
import { UserService } from './../../core/services/user.service';
import { NotificationBarService } from './../../core/services/notification-bar.service';
import { Subscription } from '@app/core/models/subscription.model';
import * as subscriptionActions from '@app/store/actions/subscription.action';

@Injectable()
export class PaymentMethodEffects {

  @Effect({dispatch: false}) add$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.ADD_PAYMENT_METHOD),
      map((action: fromPaymentMethodActions.AddPaymentMethodAction) => action.payload),
      switchMap(({nonce, cardholderName}) => {
        return this.userService.addNewPaymentMethod({nonce, cardholderName})
          .pipe(
            map((data: any) => {
              this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodCompleteAction(data));
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
              this.notificationService.open({
                title: 'Oh oh!',
                message: reason.error.message,
                type: 'error'
              });
              return of(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) update$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD),
      map((action: fromPaymentMethodActions.UpdatePaymentMethodAction) => action.payload),
      switchMap(({paymentMethodId, nonce, cardholderName}) => {
        return this.userService.updatePaymentMethod({paymentMethodId, nonce, cardholderName})
          .pipe(
            map(({paymentMethod}: { paymentMethod: PaymentMethod }) => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                id: paymentMethod._id,
                changes: paymentMethod
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
              return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) updateWithRebill$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD_WITH_REBILL),
      map((action: fromPaymentMethodActions.UpdatePaymentMethodWithRebillAction) => action.payload),
      switchMap(({paymentMethodId, nonce, cardholderName, paymentProcessor}) => {
        return this.userService.updatePaymentMethodAndRebill({paymentMethodId, nonce, cardholderName, paymentProcessor})
          .pipe(
            map(({subscriptions, paymentMethod}: { subscriptions: Subscription[], paymentMethod: PaymentMethod }) => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                id: paymentMethodId,
                changes: paymentMethod
              }));

              this.notificationService.open({
                title: 'Payment method updated!',
                message: 'Your payment profile has been updated successfully.',
                type: 'success'
              });

              if (subscriptions && subscriptions.length) {
                this.store.dispatch(new subscriptionActions.UpdateSubscriptionsCompleteAction(subscriptions.map(subscription => {
                  return {
                    id: subscription._id,
                    changes: {...subscription, declineStatus: null, paymentStatus: null}
                  };
                })));
              }
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
              this.notificationService.open({
                title: 'Oh oh!',
                message: reason.error.message,
                type: 'error'
              });
              return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  constructor(
    private userService: UserService,
    private notificationService: NotificationBarService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}

