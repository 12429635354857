<div class="page-container">
  <!-- <sucstu-modal-banner></sucstu-modal-banner> -->
  <!-- Hero section (background background with image and hero messages)-->
  <section class="hero-container" *ngIf="!isBrowser">
    <div class="hero-slides-container">
      <div class="desktop-hero-slide">
        <section>
          <div>
            <div class="slide-picture slide-picture--SSR">
              <div class="hero-title-container">
                <h2 class="hero-title">Grown with love and shipped with care.</h2>
                <div class="hero-text">Bringing you the most unique variety of succulents from Southern California right
                  to your door.
                </div>
                <div class="hero-actions">
                  <a class="hero-btn btn btn-primary--inset btn-primary--with-hover" (click)="selectPlan()">Subscribe</a>
                  <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-gift"
                     routerLink="/gift">Send the Perfect Gift</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <section class="hero-container" *ngIf="isBrowser">
    <div class="slide-nav slide-nav-container-left">
      <div class="nav-circle" (click)="moveSliderToLeft()">
        <img src="/assets/images/left-arrow.png"/>
      </div>
    </div>

    <div class="hero-slides-container">
      <div class="desktop-hero-slide">
        <swiper [config]="sliderConf">
          <div class="slide-picture slide-picture--1"
            appBgDinamic
            bgDinamicXS="/assets/images/mobile-bg-1.jpg"
            bgDinamicSM="/assets/images/tablet-bg-1.jpg"
            bgDinamicMD="/assets/images/desktop-bg-1.jpg"
          >
            <div class="hero-title-container">
              <h2 class="hero-title">Grown with love and shipped with care.</h2>
              <div class="hero-text">Bringing you the most unique variety of succulents from Southern California right
                to your door.
              </div>
              <div class="hero-actions">
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-subscribe" (click)="selectPlan()">Subscribe</a>
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-gift"
                   routerLink="/gift">Send the Perfect Gift</a>
              </div>
            </div>
          </div>

          <div class="slide-picture slide-picture--2"
            appBgDinamic
            bgDinamicXS="/assets/images/mobile-bg-2.jpg"
            bgDinamicSM="/assets/images/tablet-bg-2.jpg"
            bgDinamicMD="/assets/images/desktop-bg-2.jpg"
          >
            <div class="hero-title-container">
              <h2 class="hero-title">Everyone deserves a little something special.</h2>
              <div class="hero-text">Each month you’ll receive a hand-selected, fit-for-the-season pair of succulents
                to add that special something
                to your home, office, or garden.
              </div>
              <div class="hero-actions">
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-subscribe" (click)="selectPlan()">Subscribe</a>
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-gift"
                   routerLink="/gift">Send the Perfect Gift</a>
              </div>
            </div>
          </div>

          <div class="slide-picture slide-picture--3"
            appBgDinamic
            bgDinamicXS="/assets/images/mobile-bg-3.jpg"
            bgDinamicSM="/assets/images/tablet-bg-3.jpg"
            bgDinamicMD="/assets/images/desktop-bg-3.jpg"
          >
            <div class="hero-title-container">
              <h2 class="hero-title">We’ve always been crazy about succulents.</h2>
              <div class="hero-text">For the last 40 years, we’ve grown the industry's most beautifully unique
                succulents - everything from Afterglows
                to Baby Toes... even the occasional Topsy Turvy Echeveria!
              </div>
              <div class="hero-actions">
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-subscribe" (click)="selectPlan()">Subscribe</a>
                <a class="hero-btn btn btn-primary--inset btn-primary--with-hover hero-btn-gift"
                   routerLink="/gift">Send the Perfect Gift</a>
              </div>
            </div>
          </div>

          <div class="slide-picture slide-picture--4"
            appBgDinamic
            bgDinamicXS="/assets/images/mobile-bg-4.jpg"
            bgDinamicSM="/assets/images/tablet-bg-4.jpg"
            bgDinamicMD="/assets/images/desktop-bg-4.jpg"
          >
            <img src="/assets/images/cat.png" class="slide-picture--cat"/>
            <div class="hero-title-container">
              <h2 class="hero-title hero-title--dark">Easy to love and even easier to care for.</h2>
              <div class="hero-text hero-text--dark">(Even easier to care for than, say, your cat, who will be keeping
                a VERY close eye on your new plants).
              </div>
              <div class="hero-actions">
                <a class="hero-btn hero-btn--dark btn btn-primary--inset btn-primary--with-hover hero-btn-subscribe" (click)="selectPlan()">Subscribe</a>
                <a class="hero-btn hero-btn--dark btn btn-primary--inset btn-primary--with-hover hero-btn-gift"
                   routerLink="/gift">Send the Perfect Gift</a>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>

    <div class="slide-nav slide-nav-container-right">
      <div class="nav-circle" (click)="moveSliderToRight()">
        <img src="/assets/images/right-arrow.png"/>
      </div>
    </div>
  </section>

  <!-- Plans section-->
  <section class="plans-container">
    <h1>What you get.</h1>
    <div class="plan-card">
      <img src="/assets/images/2x-plan.jpg" alt=""/>
      <div class="plan-content">
        <div class="plan-title">
          <h2>2 plants / month</h2>
          <h4>$11 (+shipping)</h4>
        </div>
        <p class="plan-desc">Carefully curated at our second-generation farm, each subscription box comes with two
          unique 8-week-old succulents. Perfect for your home, apartment, office, or dorm, these baby plants ship in
          100% plastic-free packaging with `biodegradable pots, happy and ready to grow! Add 'em anywhere you want a pop
          of color and a boost of oxygen!</p>
        <a [attr.test-id]="'regular-subscribe-button'" class="plan-btn btn btn-primary--inset btn-primary--with-hover"
           (click)="selectPlan()">Subscribe</a>
      </div>
    </div>
  </section>


  <!-- <sucstu-mothers-banner></sucstu-mothers-banner> -->

  <!-- Company attributes -->
  <section class="company-attributes">
    <div class="attributes-card">
      <!-- Black card -->
      <div class="plans-explanation">
        <div class="plan-explanation-top">
          <h1>Subscriptions automatically renew.</h1>
          <h5>SKIP any month. GIFT any month. Cancel any time.</h5>
        </div>
        <span class="plan-explanation-divider"></span>
        <div class="plan-explanation-bottom">
          <p>Welcome to Succulent Studios, the best place to buy unique, hardy succulents online. Inside each succulent
            subscription
            box you will find your beautiful new plants safely packaged and ready to enjoy.</p>
          <h6>Includes care instructions.</h6>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/home-flower@3x.png" class="attribute-picture-flower">
          </div>
          <h2>We Grow</h2>
          <p>Our succulents are grown in sunny Southern California using only organic media and fertilizers.</p>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/truck-icon.png" class="attribute-picture-truck">
          </div>
          <h2>We Ship</h2>
          <p>Securely packaged and rushed to your door, ready to be planted!</p>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/icon-transparent@3x.png" class="attribute-picture-succulent">
          </div>
          <h2>You Enjoy!</h2>
          <p>Succulents make every home, office,or studio a happy and healthy one. Enjoy them all year long.</p>
        </div>
      </div>
      <div class="company-attributes-actions">
        <a class="btn btn-primary-inset btn-company-atrributes btn-primary--with-hover"
           (click)="selectPlan()">Subscribe</a>
        <a class="btn btn-company-succulents btn-primary-inset btn-company-atrributes btn-primary--with-hover"
           routerLink="/inspiration" queryParamsHandling="preserve">Our Succulents</a>
      </div>
    </div>
  </section>

  <!-- indications section -->
  <div class="indications">
    <div class="oxygen-gallons">
      <div class="numbers">253,172</div>
      <span>Gallons of Oxygen Made</span>
    </div>
    <div class="indications-text">
      <h2>Give a little, get a lot.</h2>
      <p>For how little they need to thrive, succulents are remarkably giving. Like other houseplants, research has
        shown that
        succulents can help cleanse and dehumidify the air (which helps increase oxygen while deterring illnesses like
        colds
        and flus), boost healing by lowering anxiety, fatigue, and pain, and even lead to increased productivity at work
        when stationed at your desk.</p>
      <p>Unlike other houseplants, succulents have two superpowers that are extremely unique. 1) succulents preserve the
        <span>consumption of water</span> (an issue close to our Southern California hearts), and 2) they
        <span>produce oxygen</span> at night while your other plants are producing carbon dioxide, which makes them
        perfect for
        bedrooms.
      </p>
      <span>Pretty incredible!</span>
    </div>
  </div>

  <div class="newsletter-container">
    <div class="newsletter-title">
      <h5>Subscribe to our newsletter</h5>
    </div>
    <div class="newsletter-form">
      <input #newsLetterEmail class="newsletter-input flex-1" type="email" placeholder="you@example.com"/>
      <button class="newsletter-btn btn flex-2" (click)="onCreateLead()">get $5 off</button>
    </div>
  </div>
</div>
