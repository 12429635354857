import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID, Renderer,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as upperFirst from 'lodash/upperFirst';

// models
import { PaymentMethod } from '@app/core/models/payment-method';

// braintree library
import * as braintreeClient from 'braintree-web/client';
import * as braintreeHostedFields from 'braintree-web/hosted-fields';
import { fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppConfig, APP_CONFIG } from '@app/app.config';
import { environment } from '@env/environment';

@Component({
  selector: 'sucstu-add-payment-form',
  styleUrls: ['./add-payment-form.component.scss'],
  templateUrl: 'add-payment-form.component.html'
})
export class AddPaymentFormComponent implements OnInit, AfterViewInit, OnChanges, AfterContentInit {

  @ViewChild('creditCardNumberField') creditCardNumberField: ElementRef;
  @ContentChild('saveButton') saveButton: ElementRef;
  @ContentChild('cancelButton') cancelButton: ElementRef;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() showLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideLoader: EventEmitter<any> = new EventEmitter<any>();

  @Input() braintreeClientToken: string;
  @Input() loading = false;
  @Input() isModal = false;

  canSubmitPayment = true;
  isFocus = false;

  isFormValid = false;
  isBraintreeClientInitialized = false;
  hideNewPaymentMethod = false;
  selectedPaymentMethod: PaymentMethod;

  isNumberValid: boolean;
  isCvvValid: boolean;
  isExpDateValid: boolean;

  showErrorMessages = false;
  showTokenizeError = false;
  isNumberTouched = false;
  isCvvTouched = false;
  isExpDateTouched = false;

  ccId: string;
  ccExpDateId: string;
  cvvId: string;

  // braintree clien sdk
  hostedFields: any;

  fields = {
    number: {
      selector: '#card-number',
      placeholder: 'Card Number'
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123'
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: '12/2019'
    }
  };

  nmiFields = {
    ccnumber: {
      selector: '#ccnumber',
      placeholder: 'Card Number',
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123',
    },
    ccexp: {
      selector: '#ccexp',
      placeholder: '12/2019',
    }
  };

  styles = {
    'input': {
      'font-size': '14px',
      'font-family': '"Omnes", Helvetica, sans-serif',
      'font-weight': '300',
      'margin-top': '5px'
    },
    '.input::placeholder': {
      'color': '#718096',
    },
    'input-modal': {
      'font-size': '14px',
      'font-family': '"Omnes", Helvetica, sans-serif',
      'font-weight': '300',
      'margin-top': '23px',
      'height': '100%'
    },
    '.input::placeholder-modal': {
      'color': '#718096',
      'font-size': '14px',
      'font-family': '"Omnes", Helvetica, sans-serif',
      'font-weight': '300',
      'margin-top': '23px',
      'height': '100%'
    },
    '.input-error::placeholder': {
      'color': 'red'
    }
  };

  form = this.fb.group({
    cardholderName: ['', Validators.required],
    nonce: ['', Validators.required],
    lastTwo: ['', Validators.required],
    cardType: ['', Validators.required],
    type: ['', Validators.required],
    description: ['', Validators.required],
    maskedNumber: [''],
    expirationDate: [''],
  });

  payment_processor = this.app_config.payment_processor;

  constructor(
    private fb: FormBuilder,
    private _renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIG) private app_config: AppConfig,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.app_config.payment_processor === 'braintree' &&
      changes.braintreeClientToken &&
      changes.braintreeClientToken.currentValue &&
      changes.braintreeClientToken.currentValue.length) {
      this.initializeBraintreeClientSDK();
    }
  }

  ngOnInit() {
    if (this.app_config.payment_processor === 'nmi') {
      this.ccId = 'ccnumber';
      this.ccExpDateId = 'ccexp';
      this.cvvId = 'cvv';
    } else {
      this.ccId = 'card-number';
      this.ccExpDateId = 'expiration-date';
      this.cvvId = 'cvv';
    }
  }

  ngAfterViewInit() {
    if (this.app_config.payment_processor === 'nmi') {
      setTimeout(async () => {
        await this.loadScript();
        await this.mountCard();
      }, 0);
    } else {
      setTimeout(() =>
        this._renderer.selectRootElement(this.creditCardNumberField.nativeElement, true).focus()
      );
    }
  }

  ngAfterContentInit() {
    fromEvent(this.saveButton.nativeElement, 'click').subscribe(() => this.onSave());
    fromEvent(this.cancelButton.nativeElement, 'click').subscribe((e: MouseEvent) => {
      e.stopPropagation();
      this.onCancel();
    });
  }

  onSave() {
    if (this.app_config.payment_processor === 'nmi') {
      if (this.isNumberValid && this.isCvvValid && this.isExpDateValid) {
        this.showLoader.emit();
      } else {
        this.hideLoader.emit();
      }
    } else {
      this.onSaveBraintree();
    }
  }

  onCancel() {
    this.hideLoader.emit();
    this.cancel.emit();
  }

  /**
   * Initialize the Braintree Client sdk.
   * This methods needs to be called every time the
   * payment form is in edit mode (is not saved) in order
   * to re-initialize the client sdk with the new data.
   *
   * @memberof SecurePaymentFormComponent
   */
  initializeBraintreeClientSDK() {
    if (isPlatformBrowser(this.platformId)) {
      braintreeClient
        .create({ authorization: this.braintreeClientToken })
        .then(client => braintreeHostedFields.create({ client, fields: this.fields, styles: this.styles }))
        .then(hostedFields => {
          this.isBraintreeClientInitialized = true;
          this.hostedFields = hostedFields;
          this.hostedFields.on('validityChange', e => {
            const state = this.hostedFields.getState();
            const formValid = Object
              .keys(state.fields)
              .every((key) => {
                this.isCvvValid = state.fields.cvv.isValid;
                this.isNumberValid = state.fields.number.isValid;
                this.isExpDateValid = state.fields.expirationDate.isValid;
                return state.fields[key].isValid;
              });
            this.isFormValid = !!formValid;
          });

          this.hostedFields.on('focus', e => {
            switch (e.emittedBy) {
              case 'number': {
                this.isNumberTouched = e.fields.number.isFocused ? true : this.isNumberTouched;
                break;
              }
              case 'cvv': {
                this.isCvvTouched = e.fields.cvv.isFocused ? true : this.isCvvTouched;
                break;
              }
              case 'expirationDate': {
                this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : this.isExpDateTouched;
                break;
              }
            }
          });

        })
        .catch(e => console.log(e));
    }
  }

  checkAndAddInputErrorClass() {
    if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
      this.hostedFields.clear('number');
      this.hostedFields.addClass('number', 'input-error');
    }
    if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
      this.hostedFields.clear('cvv');
      this.hostedFields.addClass('cvv', 'input-error');
    }
    if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
      this.hostedFields.clear('expirationDate');
      this.hostedFields.addClass('expirationDate', 'input-error');
    }
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }

  private onSaveNMI(paymentToken) {
    try {
      const paymentMethod = this.form.value;
      this.form
        .get('cardholderName')
        .setValue(paymentMethod.cardholderName.split(' ').map(upperFirst).join(' '));
      this.form.patchValue({
        paymentProcessor: 'nmi',
        type: 'CreditCard',
        nonce: paymentToken.token,
        lastTwo: paymentToken.card.number.slice(-2),
        description: `Ending in ${paymentToken.card.number.slice(-2)}`,
        cardType: upperFirst(paymentToken.card.type),
        prepaid: false,
        maskedNumber: paymentToken.card.number,
        expirationDate: `${paymentToken.card.exp.slice(0, 2)}/${paymentToken.card.exp.slice(-2)}`
      });

      if (this.form.valid) {
        this.save.emit(this.form.value);
        this.hideLoader.emit();
        this.showErrorMessages = false;
        this.showTokenizeError = false;
      }
    } catch (error) {
      this.hideLoader.emit();
      this.showErrorMessages = true;
      this.showTokenizeError = true;
      console.error('Error onSaveNMI', {
        error
      });
    }
  }

  private onSaveBraintree() {
    const paymentMethod = this.form.value;
    this.form
      .get('cardholderName')
      .setValue(paymentMethod.cardholderName.split(' ').map(upperFirst).join(' '));

    this.showLoader.emit();
    if (!paymentMethod.token && this.isFormValid && this.form.get('cardholderName').value.length) {
      this.isBraintreeClientInitialized = false;
      this.hostedFields.tokenize()
        .then(payload => {
          this.isBraintreeClientInitialized = true;
          this.form.patchValue({
            nonce: payload.nonce,
            lastTwo: payload.details.lastTwo,
            cardType: payload.details.cardType,
            type: payload.type,
            description: payload.description
          });
          this.save.emit(this.form.value);
          this.hideLoader.emit();
          this.showErrorMessages = false;
          this.showTokenizeError = false;
        })
        .catch(err => {
          this.hideLoader.emit();
          this.showErrorMessages = true;
          this.showTokenizeError = true;
          this.checkAndAddInputErrorClass();
        });
    } else {
      this.hideLoader.emit();
      this.showErrorMessages = true;
      this.checkAndAddInputErrorClass();
    }
  }

  private async loadScript(): Promise<void> {
    // this.loading = true;
    this.showLoader.emit();
    const script = document.createElement('script');
    script.setAttribute('data-tokenization-key', environment.nmiClientToken);
    script.setAttribute('data-variant', 'inline');
    script.src = 'https://secure.nmi.com/token/Collect.js';
    script.type = 'text/javascript';

    return new Promise((resolve, reject) => {
      script.onload = () => {
        resolve();
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  private async mountCard() {
    window['CollectJS'].configure({
      'fieldsAvailableCallback': () => {
        // this.loading = false;
        this.hideLoader.emit();
      },
      'validationCallback': (field, status, message) => {
        this.validateNMIHostedFields(field, status);
      },
      'timeoutCallback': () => { },
      'callback': (response) => {
        this.onSaveNMI(response);
      },
      variant: 'inline',
      customCss: {
        ...(this.isModal ? this.styles['input-modal'] : this.styles.input),
        'background-color': '#F8F8F4',
      },
      invalidCss: {
        ...this.styles['.input-error::placeholder'],
      },
      placeholderCss: {
        ...(this.isModal ? this.styles['.input::placeholder-modal'] : this.styles['.input::placeholder']),
      },
      fields: this.nmiFields
    });
  }

  private validateNMIHostedFields(field: string, status: boolean) {
    switch (field) {
      case 'ccnumber':
        this.isNumberValid = status;
        this.isNumberTouched = true;
        break;
      case 'cvv':
        this.isCvvValid = status;
        this.isCvvTouched = true;
        break;
      case 'ccexp':
        this.isExpDateValid = status;
        this.isExpDateTouched = true;
        break;
      default:
        console.log(`${field} not supported`, { field, status });
        break;
    }
    this.showErrorMessages = true;
  }
}
