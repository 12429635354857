import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'sucstu-animated-counter',
  templateUrl: './animated-counter.component.html',
  styleUrls: ['./animated-counter.component.scss']
})
export class AnimatedCounterComponent implements OnInit {

  @Input() duration: number;
  @Input() initialNumber: number;
  @Input() lastNumber: number;
  @Input() steps: number;
  displayValue: Number;
  animationCompleted = false;
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit() {
    this.displayValue = this.initialNumber;
    if (this.isBrowser) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true) {
          if (this.initialNumber && !this.animationCompleted) {
            this.animateCount();
          }
        }
      }, {threshold: [1]});

      observer.observe(this.elementRef.nativeElement);
    }
  }

  animateCount() {
    if (!this.duration) {
      this.duration = 1000;
    }

    if (typeof this.initialNumber === 'number') {
      this.counterFunc(this.initialNumber, this.lastNumber, this.duration);
      this.animationCompleted = true;
    }
  }

  counterFunc(initialValue, endValue, durationMs) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - initialValue) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = initialValue;
    let currentSinValue = 0;

    const step = () => {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      this.displayValue = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }
}
