import { NgModule } from '@angular/core';
// modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import * as fromPipes from './pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { AttributesCardsComponent } from './components/attributes-cards/attributes-cards.component';
import {NgxMaskModule} from 'ngx-mask';
import { OnlyShippingComponent } from './components/lead-forms/only-shipping/only-shipping.component';
import { AnimatedCounterComponent } from './components/animated-counter/animated-counter.component';

export const DECLARATIONS = [
  ...fromComponents.components,
  ...fromDirectives.directives,
  ...fromPipes.pipes,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forChild(),
  ],
  declarations: [...DECLARATIONS ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...DECLARATIONS,
  ],
  entryComponents: [
    ...fromComponents.leadForms,
    fromComponents.LeadBannerComponent,
  ]
})
export class SharedModule {
}
