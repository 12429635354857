import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import * as isToday from 'date-fns/is_today';

import { Payment } from '../models/payment.model';
import { Plan } from '@app/core/models/plan.model';
import { User } from '@app/core/models/user.model';
import { Coupon } from '@app/core/models/coupon.model';
import { Subscription } from '@app/core/models/subscription.model';
import { MarketingService } from '@app/core/services/marketing.service';

@Injectable()
export class ConvertionService {
  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    private sessionStorageService: SessionStorageService,
    @Inject(DOCUMENT) private document: Document,
    private readonly marketingService: MarketingService
  ) { }

  reportSubscriptionSale(data: any) {
    const { subscription, payment, user, plan, coupon } = data;
    this.reportTransactionComplete(user, subscription, coupon, payment, plan);
    this.reportIre(subscription, user, coupon, plan);
    this.reportUetq(payment);
    this.assuranceConversion();

    // Track BD with the 3for30 promo plan
    if (plan._id === '5dc06f5efd62286c229df74e') {
      this.reportBDsales({ payment, plan });
      this.reportBDSales2({ payment, plan });
    }
  }

  reportOTBSale({ user, payment, shipment, box }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      const shippingCosts = box.shippingCosts;
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? shipment._id : `TEST_${shipment._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': box.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
      });
      this.assuranceConversion();
    }
  }

  reportShipmentSale({ user, subscription, coupon, payment, plan }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {

      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': plan.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
        'sourceParam': utmData && utmData.utm_source,
        'sourceAffiliateRef': affiliateRef,
        'coupon': coupon && coupon.code.toLowerCase()
      });
      this.assuranceConversion();
    }
  }

  private reportBDsales({ payment, plan }) {
    const script = this.document.createElement('iframe');
    script.width = '1px';
    script.height = '1px';
    script.setAttribute('nid', '104');
    script.setAttribute('oid', '15');
    script.setAttribute('transaction_id', '');
    script.setAttribute('amount', (plan.price).toString());

    (script as any).order = {
      oid: payment.subscription,
      amt: plan.price,
      bs: '',
      bc: '',
      cc: '',
      items: []
    };

    script.src = 'https://bradsdeals.servtrk.com/?nid=104'
      + script.getAttribute('nid')
      + '&oid=15'
      + script.getAttribute('oid')
      + '&transaction_id='
      + script.getAttribute('transaction_id') + '';

    if (parseInt(script.getAttribute('amount'), 10) > 0) {
      script.src += '&amount=' + script.getAttribute('amount');
    }

    if ((script as any).order) {
      script.src += '&order=' + encodeURIComponent(JSON.stringify((script as any).order));
    }

    this.document.body.appendChild(script);
  }

  private reportBDSales2({ payment, plan }) {
    const script = this.document.createElement('iframe');
    script.width = '1px';
    script.height = '1px';
    script.setAttribute('nid', '104');
    script.setAttribute('oid', '17');
    script.setAttribute('transaction_id', '');
    script.setAttribute('amount', (plan.price).toString());

    (script as any).order = {
      oid: payment.subscription,
      amt: plan.price,
      bs: '',
      bc: '',
      cc: '',
      items: []
    };

    script.src = 'https://www.ttysetrk.com/?nid=104&oid=17&transaction_id='
      + script.getAttribute('transaction_id')
      + '&amount='
      + script.getAttribute('amount')
      + '';

    if (parseInt(script.getAttribute('amount'), 10) > 0) {
      script.src += '&amount=' + script.getAttribute('amount');
    }

    if ((script as any).order) {
      script.src += '&order=' + encodeURIComponent(JSON.stringify((script as any).order));
    }

    this.document.body.appendChild(script);
  }

  private assuranceConversion() {
    try {
      if (this.app_config.production) {
        const script = document.createElement('script');
        script.src = 'https://assurance.mimtid.com/adstart/t.js?c=4EF238BDE8-conversion';
        script.type = 'text/javascript';

        new Promise<void>((resolve, reject) => {
          script.onload = () => {
            resolve();
          };
          script.onerror = (error: any) => {
            reject(error);
          };
          document.getElementsByTagName('body')[0].appendChild(script);
        });
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  private reportTransactionComplete(user, subscription: Subscription, coupon: Coupon, payment: Payment, plan: Plan) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {

      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);

      this.marketingService.reportPurchase({
        value: payment && payment.amount || 0,
        planName: plan.name,
        id: this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
      })
        .subscribe((response) => {
          (window as any).dataLayer.push({
            'event': 'transactionComplete',
            eventID: response.eventId,
            'transactionId': this.app_config.production && this.app_config.name === 'production'
              ? subscription._id : `TEST_${subscription._id}`,
            'transactionTotal': payment && payment.amount || 0,
            'transactionTax': payment && payment.taxes || 0,
            'transactionShipping': subscription.isPrepay ? shippingCosts * (plan.term as any) : shippingCosts * plan.shipmentsAtCheckout,
            'transactionType': plan.type,
            'transactionProducts': [
              {
                'name': plan.title,
                'sku': plan.name,
                'price': plan.price,
                'quantity': subscription.isPrepay ? plan.term : 1
              }
            ],
            'customerEmail': user.email,
            'sourceParam': utmData && utmData.utm_source,
            'sourceAffiliateRef': affiliateRef,
            'coupon': coupon && coupon.code.toLowerCase()
          });
        })
    }
  }

  private reportIre(subscription: Subscription, user: User, coupon: Coupon, plan: Plan) {
    if (isPlatformBrowser(this.platformId) && (window as any).ire) {
      window['ire']('identify', {
        customerId: user._id,
        customerEmail: user.email
      });

      window['ire']('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: user._id,
        customerStatus: isToday(user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: plan.price,
            category: (plan.type as string).toLowerCase(),
            sku: plan.name,
            quantity: 1
          }
        ]
      });
    }
  }

  private reportUetq(payment: Payment) {
    if (isPlatformBrowser(this.platformId) && (window as any).uetq && (window as any).uetq.push) {
      window['uetq'].push('event', 'checkout', {
        revenue_value: payment && payment.amount || 0,
        currency: 'USD'
      });
    }
  }

}
