import { AfterViewInit, ElementRef, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { AppConfig } from '@app/app.config';
// RxJS
import { timer } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
// services
import * as fromServices from './../../../core/services';
import { LEAD_FORM_TYPE } from '@app/core/services/lead-form-component.service';
import { ErrorService } from '@app/error';
// actions
import * as fromPlansActions from './../../../store/actions/plans.actions';
import * as coupons from '@app/store/actions/coupon.actions';
// selectors
import * as fromCoupons from '@app/store/selectors/coupons.selector';
import { isPlatformBrowser } from '@angular/common';
import { MarketingService } from '@app/core/services/marketing.service';
import * as isEmail from 'validator/lib/isEmail';
import { GmailModalService } from '@app/core/services/gmail-modal.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, store, metaService, titleService, route, errorService, localStorage, sessionStorage, platformId, leadService, plansService, app_config, leadFormService, notificationBarService, marketingService, gmailModalService) {
        this.router = router;
        this.store = store;
        this.metaService = metaService;
        this.titleService = titleService;
        this.route = route;
        this.errorService = errorService;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.platformId = platformId;
        this.leadService = leadService;
        this.plansService = plansService;
        this.app_config = app_config;
        this.leadFormService = leadFormService;
        this.notificationBarService = notificationBarService;
        this.marketingService = marketingService;
        this.gmailModalService = gmailModalService;
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.sliderConf = {
            direction: 'horizontal',
            slidesPerView: 1,
            pagination: true,
            autoplay: false
        };
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.updateTag({
            name: 'description',
            content: 'Simple signup. Stunning succulents. We ship two organically-grown succulents from our SoCal nursery straight to your door. $5 off your first month!'
        });
        this.sessionStorage.observe('oxygenGallons').subscribe(function (value) { return _this.oxygenGallons = value; });
        this.titleService.setTitle('Succulent Studios | Monthly Succulent Subscription Box');
        this.coupon$ = this.store.pipe(select(fromCoupons.getCouponData));
        this.coupon$
            .pipe(filter(function (coupon) { return !!coupon; }))
            .pipe(take(1))
            .subscribe(function (coupon) {
            _this.coupon = coupon;
        });
    };
    HomeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        timer(0).pipe(switchMap(function () { return _this.route.queryParams; }), switchMap(function (queryParams) {
            return queryParams.lf === 'win-it-wednesday'
                ? _this.leadFormService.canShowLeadFormWithoutLS$
                : _this.leadFormService.canShowLeadForm$;
        }), filter(Boolean), switchMap(function () { return _this.route.queryParams; }), map(function (queryParams) {
            console.log({ queryParams: queryParams });
            if (queryParams.lf === 'free-shipping') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.FREE_SHIPPING),
                    coupon: 'SHIPPINGSUCCS',
                };
            }
            else if (queryParams.lf === 'win-it-wednesday') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.WIN_IT_WEDNESDAY),
                    coupon: '',
                };
            }
            else if (queryParams.lf === 'six-off') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.SIX_OFF),
                    coupon: 'SIXDOLLARS',
                };
            }
            else if (queryParams.lf === 'fifty-off') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.FIFTY_OFF),
                    coupon: 'NEWSSUCCS50',
                };
            }
            else if (queryParams.lf === 'only-shipping') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.ONLY_SHIPPING),
                    coupon: 'ONLYSHIPPING',
                };
            }
            else if (queryParams.lf === 'rare-collective') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.RARE_COLLECTIVE),
                    coupon: 'FIVEDOLLARS',
                };
            }
            else if (queryParams.lf === 'colorful-classic') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.COLORFUL_CLASSIC),
                    coupon: 'FIVEDOLLARS',
                };
            }
            else if (queryParams.lf === 'cactus-gwp') {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.FREE_SHIPPING),
                    coupon: 'CACTUSGIFT',
                };
            }
            else {
                return {
                    leadFormInstance: _this.leadFormService.open(LEAD_FORM_TYPE.FIVE_OFF),
                    coupon: 'FIVEDOLLARS',
                };
            }
        }), filter(function (data) { return !!data.leadFormInstance; }), take(1))
            .subscribe(function (_a) {
            var leadFormInstance = _a.leadFormInstance, coupon = _a.coupon;
            leadFormInstance.submit.subscribe(function () {
                _this.store.dispatch(new coupons.VerifyCouponAction(coupon));
            });
            leadFormInstance.close.subscribe(function () {
                _this.localStorage.store('isBannerActive', false);
            });
        });
    };
    HomeComponent.prototype.moveSliderToLeft = function () {
        this.swiperSlider.directiveRef.prevSlide();
    };
    HomeComponent.prototype.moveSliderToRight = function () {
        this.swiperSlider.directiveRef.nextSlide();
    };
    HomeComponent.prototype.onCreateLead = function () {
        var _this = this;
        var emailInput = this.newsLetterEmail.nativeElement;
        var email = emailInput.value;
        if (!email.length || !isEmail(email)) {
            this.notificationBarService.open({
                mode: 'error',
                type: 'coupon',
                title: 'Validation',
                message: "Please use a valid email",
            });
            return;
        }
        this.createLead(email)
            .pipe(take(1))
            .subscribe(function () {
        }, function (error) {
            var message = "Error trying to create/update Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
    };
    HomeComponent.prototype.createLead = function (email) {
        var _this = this;
        if (email === void 0) { email = ''; }
        var uuid = this.localStorage.retrieve('uuid');
        var utmData = this.route.snapshot.queryParams;
        var input = this.newsLetterEmail.nativeElement;
        email = email.length > 0 ? email : input.value;
        this.localStorage.store('customerEmail', email);
        return this.leadService
            .create({ uuid: uuid, email: email, utmData: utmData, leadForm: 'five-off' })
            .pipe(tap(function (data) {
            if (isPlatformBrowser(_this.platformId) && _this.app_config.production && _this.app_config.name === 'production') {
                _this.marketingService
                    .reportLead(email)
                    .subscribe();
                if (email && email.includes('@gmail.co')) {
                    _this.gmailModalService.showGmailModal$.next(true);
                }
            }
            if (!_this.coupon) {
                _this.store.dispatch(new coupons.VerifyCouponAction('fivedollars'));
            }
        }));
    };
    HomeComponent.prototype.selectPlan = function () {
        var planId = this.plansService.REGULAR_PLAN;
        this.store.dispatch(new fromPlansActions.SelectPlanAction(planId));
        this.router.navigate(['/checkout'], { queryParamsHandling: 'preserve' });
    };
    return HomeComponent;
}());
export { HomeComponent };
