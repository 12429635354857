import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromPaymentMethodActions from './../actions/payment-methods.actions';
// services
import { UserService } from './../../core/services/user.service';
import { NotificationBarService } from './../../core/services/notification-bar.service';
import * as subscriptionActions from '@app/store/actions/subscription.action';
var PaymentMethodEffects = /** @class */ (function () {
    function PaymentMethodEffects(userService, notificationService, actions$, store) {
        var _this = this;
        this.userService = userService;
        this.notificationService = notificationService;
        this.actions$ = actions$;
        this.store = store;
        this.add$ = this.actions$
            .pipe(ofType(fromPaymentMethodActions.ADD_PAYMENT_METHOD), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var nonce = _a.nonce, cardholderName = _a.cardholderName;
            return _this.userService.addNewPaymentMethod({ nonce: nonce, cardholderName: cardholderName })
                .pipe(map(function (data) {
                _this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodCompleteAction(data));
            }), catchError(function (reason) {
                _this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
                _this.notificationService.open({
                    title: 'Oh oh!',
                    message: reason.error.message,
                    type: 'error'
                });
                return of(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
            }));
        }));
        this.update$ = this.actions$
            .pipe(ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var paymentMethodId = _a.paymentMethodId, nonce = _a.nonce, cardholderName = _a.cardholderName;
            return _this.userService.updatePaymentMethod({ paymentMethodId: paymentMethodId, nonce: nonce, cardholderName: cardholderName })
                .pipe(map(function (_a) {
                var paymentMethod = _a.paymentMethod;
                _this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                    id: paymentMethod._id,
                    changes: paymentMethod
                }));
            }), catchError(function (reason) {
                _this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
                return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            }));
        }));
        this.updateWithRebill$ = this.actions$
            .pipe(ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD_WITH_REBILL), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var paymentMethodId = _a.paymentMethodId, nonce = _a.nonce, cardholderName = _a.cardholderName, paymentProcessor = _a.paymentProcessor;
            return _this.userService.updatePaymentMethodAndRebill({ paymentMethodId: paymentMethodId, nonce: nonce, cardholderName: cardholderName, paymentProcessor: paymentProcessor })
                .pipe(map(function (_a) {
                var subscriptions = _a.subscriptions, paymentMethod = _a.paymentMethod;
                _this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                    id: paymentMethodId,
                    changes: paymentMethod
                }));
                _this.notificationService.open({
                    title: 'Payment method updated!',
                    message: 'Your payment profile has been updated successfully.',
                    type: 'success'
                });
                if (subscriptions && subscriptions.length) {
                    _this.store.dispatch(new subscriptionActions.UpdateSubscriptionsCompleteAction(subscriptions.map(function (subscription) {
                        return {
                            id: subscription._id,
                            changes: tslib_1.__assign({}, subscription, { declineStatus: null, paymentStatus: null })
                        };
                    })));
                }
            }), catchError(function (reason) {
                _this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
                _this.notificationService.open({
                    title: 'Oh oh!',
                    message: reason.error.message,
                    type: 'error'
                });
                return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PaymentMethodEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PaymentMethodEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PaymentMethodEffects.prototype, "updateWithRebill$", void 0);
    return PaymentMethodEffects;
}());
export { PaymentMethodEffects };
