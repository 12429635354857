<div class="container">
  <section class="faq-container">

    <div class="title-container">
      <h1 class="section__title faq__title text-medium">Faq</h1>
      <p>Questions? Check out commonly asked questions and their answers here. Feel free to email us also: <a href="mailto:hello@succulent.studio">hello@succulent.studio</a>.</p>
      <p>Ready to get two stunning succulents every month?
        <a routerLink="/checkout">Subscribe today</a>.
      </p>
    </div>

    <div class="questions-container">
      <sucstu-faq-question>
        <h3 class="question__title">What size are the succulents you send?</h3>
        <p class="question__answer">
          The succulents we send are babies, only about 8 weeks old. They come planted in 2" biodegradable pots and are
          ready to grow and thrive right out of the box!
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">Do the plants come in pots?</h3>
        <p class="question__answer">
          Our baby succulents arrive in 100% biodegradable pots, perfectly cute and ready to plant! They'll be happy and
          healthy in these pots for 1-2 rounds of watering (roughly a few weeks), at which point the pots will begin
          softening and should be planted into new homes. Just place your succulent--pot and all--into a container of
          your choice (preferably one with drainage holes), and surround with soil. After a few months, the original pot
          will have completely degraded!
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">When will I get my succulents?</h3>
        <p class="question__answer">
          <strong class="mb-3">Succulents are shipped anywhere in the U.S.</strong>
          Once an order is placed, we get to work preparing your new little succulents for their new home! We select two
          healthy plants grown right here at our nursery, trim them as needed, carefully pot them in coconut coir, and
          package them safely in their box. Then they'll be on their way!
        </p>
        <p class="question__answer">
          Once shipped, you will receive an email with tracking information and an estimated delivery day.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">What plants will I receive?</h3>
        <p class="question__answer">
          We carefully curate each month's box to include two surprise succulents. Grown here at our nursery, we plan
          based on seasonality and hardiness. On the first of every month, we announce that month's plants! Follow us on
          social media to see what plants we've planned for you! *If you subscribe any time within a certain month,
          you'll receive that month's plants.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">How much is shipping?</h3>
        <p class="question__answer">
          Shipping is a flat rate of $6.50 anywhere in the U.S.! Don't forget to use code FIVEDOLLARS to get $5 off your
          first month.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">What is the best way to take care of my succulents?</h3>
        <p class="question__answer">
          Everything you need to know about succulent care can be found in our blog post:
          <a routerLink="/blog/posts/5-simple-tricks-to-giving-your-succulents-their-best-life" target="a_blank">https://succulent.studio/blog/posts/5-simple-tricks-to-giving-your-succulents-their-best-life</a>.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">What happens if my succulents break during shipping?</h3>
        <p class="question__answer">
          Fortunately, succulents are SUPER hardy, and can almost always be revived.
          Check out our blog post on reviving busted succulents:
          <a href="https://succulent.studio/blog/posts/how-to-revive-your-busted-succulents" target="_blank">https://succulent.studio/blog/posts/how-to-revive-your-busted-succulents</a>
          We also have a 100% satisfaction guarantee, so we’re happy to ship you a new box of succulents if your succulents arrive in a state of disrepair.
          Just email us at hello@succulent.studio (you must include an image for us to best help!) and we’ll get you squared away!
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">Can I skip a month?</h3>
        <p class="question__answer">
          Absolutely! Click the Log In link on the site, then under the Subscriptions header click the SKIP IT button.
          You will not
          be charged for skipping a month, and your subscription will resume the following month.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">Can I gift a month?</h3>
        <p class="question__answer">
          That’s so nice of you! You sure can. Click the Log In link on the site, then under the Subscriptions header
          click the GIFT
          IT button. Your next shipment will be sent to the person of your choosing, and your subscription will resume
          the
          following month.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">Do you have any other gift options?</h3>
        <p class="question__answer">
          We do! We have 3, 6, and 12-month gift options that are perfect for anyone who deserves something special,
          whether they're a succulent novice or full-blown plant hoarder! Head over to our <a routerLink="/gift">Gift
          page</a> to select the plan they'll love, write 'em a note, pick the day you want us to ship their first box,
          and we'll handle the rest!
        </p>
        <p class="question__answer">
          Gifts are the same price as a regular subscription: $11/month (+ $6.50 shipping). You'll pay for the first
          month upfront, then your gift will set to auto-pay for the remaining months. Happy gifting!
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">How do I cancel my subscription?</h3>
        <p class="question__answer">
          Head over to your Account page on the site and click the button that says CANCEL SUBSCRIPTION.
        </p>
      </sucstu-faq-question>
      <sucstu-faq-question>
        <h3 class="question__title">Succulent Studios looks AWESOME! Can I visit your nursery?</h3>
        <p class="question__answer">
          We don't actually sell succulents out of our nursery in Fallbrook or our warehouse in Long Beach. But if you
          want to come say hi to our team at the warehouse, we'd love to see you! We're located at 795 Long Beach BLVD
          STE 104, Long Beach CA 90813.
        </p>
      </sucstu-faq-question>
    </div>
  </section>
</div>
