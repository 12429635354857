export const environment = {
  name: 'staging',
  slug: 'succulent-studio',
  production: true,
  api_uri: 'https://staging.succulent.studio/api',
  api_uri_v2: '/api-v2',
  app_version: '2.0.4',
  btClientToken: 'sandbox_kt43hnf7_q5fkgw3s9pj28wwz',
  payment_processor: 'braintree',
  nmiClientToken: '6m2CN8-66AsSK-sY252z-u7R2tb',
  googlePayMerchantId: null,
  gtmContainerId: 'GTM-N6X9FBW',
  propsToClearOnLS: {
    selectedPlanId: true,
    checkoutUser: true
  },
  pca_predict: {
    key: 'ZW39-HX97-ZC55-NM99',
    api_endpoint: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json.ws'
  },
  bugSnagKey: '96ce495f6077e8c211d161dbbe1a6f33'
};
