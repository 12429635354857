<div class="lead-banner flex flex-col items-center justify-center md:flex-row md:flex-wrap md:px-10 px-2">
  <div class="w-full h-full absolute top-0 left-0 lead-darker"></div>

  <div class="md:order-2 w-full px-4 md:w-4/12 join-the-club md:flex md:items-center relative">
    <h1 class="join-the-club__text md:text-right">
      JOIN <br> THE CLUB
    </h1>
  </div>

  <section class="md:order-1 w-full px-4 md:w-8/12 md:mr-auto md:flex md:flex-col md:justify-center lg:w-7/12 relative">
    <div class="w-full weve-got mb-8">
      <p class="m-0 text-black text-center weve-got__text mb-2 font-semibold">
        We've got some plan(t)s in the making ... <br> wanna come?
      </p>
      <p class="m-0 text-black text-center weve-got__text mb-1">
        You should come. Gifts won’t buy themselves. Gift succulents!
      </p>
    </div>

    <div class="w-full off-number text-center mb-8">
      <h2 class="off-number__6-off-title">
        3 MONTHS FOR $30
        <br>
      </h2>
      <!--      <p class="off-number__6-off-text text-white">and be on the road to plant happiness.</p>-->
    </div>

    <div class="w-full dont-worry text-center">
      <p class="m-0 p-0 text-black dont-worry__text">
        <span class="font-bold text-base" style="font-family: 'Colfax', Helvetica, sans-serif">
          Get this deal, plus other special offers.
        </span>
        <br>
        Don’t worry, we won’t ping you in the middle of the night when your plants are sleeping.
      </p>
    </div>

    <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email text-center text-white mb-2">
    Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?
  </span>

    <form class="form flex flex-col md:flex-row md:flex-wrap md:justify-center" [formGroup]="form">
      <div class="flex w-full md:w-6/12 pr-4 md:w-auto md:flex-1 md:mr-2 bg-white form__input">
        <input formControlName="email" class="w-full" id="six-off-email" type="text" placeholder="Your email"/>
      </div>

      <div class="w-full md:w-6/12">
        <button (click)="submitLeadForm()"
                [disabled]="form.invalid || (form.value.email === '' && form.value.phone === '') || savingLead"
                class="w-full love-succulents">
          I Love Succulents
        </button>
      </div>
    </form>

    <div class="cursor-pointer w-full no-thanks text-center text-black">
      <p (click)="closeLeadForm()" class="m-0 p-0 no-thanks__text">No thanks. I don’t want to get free shipping on
        3 months of succulents.</p>
    </div>
  </section>
</div>
