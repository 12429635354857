import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
// selectors
import * as fromRoot from './../../../store/selectors';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// services
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ClaimService } from './../../services';
import { map, tap } from 'rxjs/operators';
import * as fromOrderActions from '@app/latest-checkout/store/actions/order.actions';
var ClaimComponent = /** @class */ (function () {
    function ClaimComponent(router, fb, store, claimService, localStorage, sessionStorage) {
        this.router = router;
        this.fb = fb;
        this.store = store;
        this.claimService = claimService;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.form = this.fb.group({
            email: '',
            claimCode: ''
        });
        this.userExist = false;
        this.isAuthenticated = false;
        this.requesting = false;
    }
    ClaimComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user$ = this.store.pipe(select(fromRoot.getUserState));
        this.user$
            .subscribe(function (value) {
            _this.isAuthenticated = value.isAuthenticated;
            _this.form.get('email').setValue(value.data.email);
        });
    };
    ClaimComponent.prototype.claimSubscription = function () {
        var _this = this;
        this.requesting = true;
        this.claimService.checkClaimCode(this.form.value)
            .pipe(tap(function (response) {
            _this.sessionStorage.store('claimCode', _this.form.value.claimCode);
            _this.localStorage.store('customerEmail', _this.form.value.email);
            if (response.recipient && response.address) {
                _this.localStorage.store('customerAddress', JSON.stringify(response.address));
                _this.localStorage.store('customerRecipient', JSON.stringify(response.recipient));
            }
            _this.store.dispatch(new fromOrderActions.ApplyClaimCode(_this.form.value.claimCode));
        }), map(function (response) { return _this.checkUserExist(response); }))
            .subscribe(function () {
        }, function (response) {
            _this.requesting = false;
            _this.store.dispatch(new notificationBarActions.OpenNotificationBarAction({
                title: '',
                message: response.error.message,
                mode: 'error',
                type: 'notification',
            }));
        });
    };
    ClaimComponent.prototype.checkUserExist = function (_a) {
        var userExist = _a.userExist;
        this.requesting = false;
        if (userExist && !this.isAuthenticated) {
            this.userExist = true;
            return;
        }
        this.router.navigate(['/checkout'], {
            queryParams: {
                planId: '613fa5c6e0e8171ffb36b7f6',
                isPrepay: true,
                ccode: '3MFREESHIP',
                claimCode: this.sessionStorage.retrieve('claimCode')
            }
        });
    };
    ClaimComponent.prototype.goToLogin = function () {
        this.router.navigate(['/login'], { queryParams: { redirect: 'claim-checkout' } });
    };
    return ClaimComponent;
}());
export { ClaimComponent };
