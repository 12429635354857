import { Pipe, PipeTransform } from '@angular/core';
import * as isBefore from 'date-fns/is_before';

@Pipe({
  name: 'dateInFuture'
})
export class DateInFuturePipe implements PipeTransform {

  transform(printDate: any, args?: any): any {
    const currentTime = new Date();
    return !isBefore(new Date(printDate), currentTime);
  }

}
