import * as tslib_1 from "tslib";
import { AfterContentInit, AfterViewInit, ElementRef, EventEmitter, OnChanges, OnInit, Renderer2, SimpleChanges, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as upperFirst from 'lodash/upperFirst';
// braintree library
import * as braintreeClient from 'braintree-web/client';
import * as braintreeHostedFields from 'braintree-web/hosted-fields';
import { fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppConfig } from '@app/app.config';
import { environment } from '@env/environment';
var AddPaymentFormComponent = /** @class */ (function () {
    function AddPaymentFormComponent(fb, _renderer, platformId, app_config) {
        this.fb = fb;
        this._renderer = _renderer;
        this.platformId = platformId;
        this.app_config = app_config;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.showLoader = new EventEmitter();
        this.hideLoader = new EventEmitter();
        this.loading = false;
        this.isModal = false;
        this.canSubmitPayment = true;
        this.isFocus = false;
        this.isFormValid = false;
        this.isBraintreeClientInitialized = false;
        this.hideNewPaymentMethod = false;
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        this.isNumberTouched = false;
        this.isCvvTouched = false;
        this.isExpDateTouched = false;
        this.fields = {
            number: {
                selector: '#card-number',
                placeholder: 'Card Number'
            },
            cvv: {
                selector: '#cvv',
                placeholder: '123'
            },
            expirationDate: {
                selector: '#expiration-date',
                placeholder: '12/2019'
            }
        };
        this.nmiFields = {
            ccnumber: {
                selector: '#ccnumber',
                placeholder: 'Card Number',
            },
            cvv: {
                selector: '#cvv',
                placeholder: '123',
            },
            ccexp: {
                selector: '#ccexp',
                placeholder: '12/2019',
            }
        };
        this.styles = {
            'input': {
                'font-size': '14px',
                'font-family': '"Omnes", Helvetica, sans-serif',
                'font-weight': '300',
                'margin-top': '5px'
            },
            '.input::placeholder': {
                'color': '#718096',
            },
            'input-modal': {
                'font-size': '14px',
                'font-family': '"Omnes", Helvetica, sans-serif',
                'font-weight': '300',
                'margin-top': '23px',
                'height': '100%'
            },
            '.input::placeholder-modal': {
                'color': '#718096',
                'font-size': '14px',
                'font-family': '"Omnes", Helvetica, sans-serif',
                'font-weight': '300',
                'margin-top': '23px',
                'height': '100%'
            },
            '.input-error::placeholder': {
                'color': 'red'
            }
        };
        this.form = this.fb.group({
            cardholderName: ['', Validators.required],
            nonce: ['', Validators.required],
            lastTwo: ['', Validators.required],
            cardType: ['', Validators.required],
            type: ['', Validators.required],
            description: ['', Validators.required],
            maskedNumber: [''],
            expirationDate: [''],
        });
        this.payment_processor = this.app_config.payment_processor;
    }
    AddPaymentFormComponent.prototype.ngOnChanges = function (changes) {
        if (this.app_config.payment_processor === 'braintree' &&
            changes.braintreeClientToken &&
            changes.braintreeClientToken.currentValue &&
            changes.braintreeClientToken.currentValue.length) {
            this.initializeBraintreeClientSDK();
        }
    };
    AddPaymentFormComponent.prototype.ngOnInit = function () {
        if (this.app_config.payment_processor === 'nmi') {
            this.ccId = 'ccnumber';
            this.ccExpDateId = 'ccexp';
            this.cvvId = 'cvv';
        }
        else {
            this.ccId = 'card-number';
            this.ccExpDateId = 'expiration-date';
            this.cvvId = 'cvv';
        }
    };
    AddPaymentFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.app_config.payment_processor === 'nmi') {
            setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadScript()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.mountCard()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, 0);
        }
        else {
            setTimeout(function () {
                return _this._renderer.selectRootElement(_this.creditCardNumberField.nativeElement, true).focus();
            });
        }
    };
    AddPaymentFormComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        fromEvent(this.saveButton.nativeElement, 'click').subscribe(function () { return _this.onSave(); });
        fromEvent(this.cancelButton.nativeElement, 'click').subscribe(function (e) {
            e.stopPropagation();
            _this.onCancel();
        });
    };
    AddPaymentFormComponent.prototype.onSave = function () {
        if (this.app_config.payment_processor === 'nmi') {
            if (this.isNumberValid && this.isCvvValid && this.isExpDateValid) {
                this.showLoader.emit();
            }
            else {
                this.hideLoader.emit();
            }
        }
        else {
            this.onSaveBraintree();
        }
    };
    AddPaymentFormComponent.prototype.onCancel = function () {
        this.hideLoader.emit();
        this.cancel.emit();
    };
    /**
     * Initialize the Braintree Client sdk.
     * This methods needs to be called every time the
     * payment form is in edit mode (is not saved) in order
     * to re-initialize the client sdk with the new data.
     *
     * @memberof SecurePaymentFormComponent
     */
    AddPaymentFormComponent.prototype.initializeBraintreeClientSDK = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            braintreeClient
                .create({ authorization: this.braintreeClientToken })
                .then(function (client) { return braintreeHostedFields.create({ client: client, fields: _this.fields, styles: _this.styles }); })
                .then(function (hostedFields) {
                _this.isBraintreeClientInitialized = true;
                _this.hostedFields = hostedFields;
                _this.hostedFields.on('validityChange', function (e) {
                    var state = _this.hostedFields.getState();
                    var formValid = Object
                        .keys(state.fields)
                        .every(function (key) {
                        _this.isCvvValid = state.fields.cvv.isValid;
                        _this.isNumberValid = state.fields.number.isValid;
                        _this.isExpDateValid = state.fields.expirationDate.isValid;
                        return state.fields[key].isValid;
                    });
                    _this.isFormValid = !!formValid;
                });
                _this.hostedFields.on('focus', function (e) {
                    switch (e.emittedBy) {
                        case 'number': {
                            _this.isNumberTouched = e.fields.number.isFocused ? true : _this.isNumberTouched;
                            break;
                        }
                        case 'cvv': {
                            _this.isCvvTouched = e.fields.cvv.isFocused ? true : _this.isCvvTouched;
                            break;
                        }
                        case 'expirationDate': {
                            _this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : _this.isExpDateTouched;
                            break;
                        }
                    }
                });
            })
                .catch(function (e) { return console.log(e); });
        }
    };
    AddPaymentFormComponent.prototype.checkAndAddInputErrorClass = function () {
        if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
            this.hostedFields.clear('number');
            this.hostedFields.addClass('number', 'input-error');
        }
        if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
            this.hostedFields.clear('cvv');
            this.hostedFields.addClass('cvv', 'input-error');
        }
        if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
            this.hostedFields.clear('expirationDate');
            this.hostedFields.addClass('expirationDate', 'input-error');
        }
    };
    AddPaymentFormComponent.prototype.isFieldValid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    AddPaymentFormComponent.prototype.onSaveNMI = function (paymentToken) {
        try {
            var paymentMethod = this.form.value;
            this.form
                .get('cardholderName')
                .setValue(paymentMethod.cardholderName.split(' ').map(upperFirst).join(' '));
            this.form.patchValue({
                paymentProcessor: 'nmi',
                type: 'CreditCard',
                nonce: paymentToken.token,
                lastTwo: paymentToken.card.number.slice(-2),
                description: "Ending in " + paymentToken.card.number.slice(-2),
                cardType: upperFirst(paymentToken.card.type),
                prepaid: false,
                maskedNumber: paymentToken.card.number,
                expirationDate: paymentToken.card.exp.slice(0, 2) + "/" + paymentToken.card.exp.slice(-2)
            });
            if (this.form.valid) {
                this.save.emit(this.form.value);
                this.hideLoader.emit();
                this.showErrorMessages = false;
                this.showTokenizeError = false;
            }
        }
        catch (error) {
            this.hideLoader.emit();
            this.showErrorMessages = true;
            this.showTokenizeError = true;
            console.error('Error onSaveNMI', {
                error: error
            });
        }
    };
    AddPaymentFormComponent.prototype.onSaveBraintree = function () {
        var _this = this;
        var paymentMethod = this.form.value;
        this.form
            .get('cardholderName')
            .setValue(paymentMethod.cardholderName.split(' ').map(upperFirst).join(' '));
        this.showLoader.emit();
        if (!paymentMethod.token && this.isFormValid && this.form.get('cardholderName').value.length) {
            this.isBraintreeClientInitialized = false;
            this.hostedFields.tokenize()
                .then(function (payload) {
                _this.isBraintreeClientInitialized = true;
                _this.form.patchValue({
                    nonce: payload.nonce,
                    lastTwo: payload.details.lastTwo,
                    cardType: payload.details.cardType,
                    type: payload.type,
                    description: payload.description
                });
                _this.save.emit(_this.form.value);
                _this.hideLoader.emit();
                _this.showErrorMessages = false;
                _this.showTokenizeError = false;
            })
                .catch(function (err) {
                _this.hideLoader.emit();
                _this.showErrorMessages = true;
                _this.showTokenizeError = true;
                _this.checkAndAddInputErrorClass();
            });
        }
        else {
            this.hideLoader.emit();
            this.showErrorMessages = true;
            this.checkAndAddInputErrorClass();
        }
    };
    AddPaymentFormComponent.prototype.loadScript = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            return tslib_1.__generator(this, function (_a) {
                // this.loading = true;
                this.showLoader.emit();
                script = document.createElement('script');
                script.setAttribute('data-tokenization-key', environment.nmiClientToken);
                script.setAttribute('data-variant', 'inline');
                script.src = 'https://secure.nmi.com/token/Collect.js';
                script.type = 'text/javascript';
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        script.onload = function () {
                            resolve();
                        };
                        script.onerror = function (error) {
                            reject(error);
                        };
                        document.getElementsByTagName('body')[0].appendChild(script);
                    })];
            });
        });
    };
    AddPaymentFormComponent.prototype.mountCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                window['CollectJS'].configure({
                    'fieldsAvailableCallback': function () {
                        // this.loading = false;
                        _this.hideLoader.emit();
                    },
                    'validationCallback': function (field, status, message) {
                        _this.validateNMIHostedFields(field, status);
                    },
                    'timeoutCallback': function () { },
                    'callback': function (response) {
                        _this.onSaveNMI(response);
                    },
                    variant: 'inline',
                    customCss: tslib_1.__assign({}, (this.isModal ? this.styles['input-modal'] : this.styles.input), { 'background-color': '#F8F8F4' }),
                    invalidCss: tslib_1.__assign({}, this.styles['.input-error::placeholder']),
                    placeholderCss: tslib_1.__assign({}, (this.isModal ? this.styles['.input::placeholder-modal'] : this.styles['.input::placeholder'])),
                    fields: this.nmiFields
                });
                return [2 /*return*/];
            });
        });
    };
    AddPaymentFormComponent.prototype.validateNMIHostedFields = function (field, status) {
        switch (field) {
            case 'ccnumber':
                this.isNumberValid = status;
                this.isNumberTouched = true;
                break;
            case 'cvv':
                this.isCvvValid = status;
                this.isCvvTouched = true;
                break;
            case 'ccexp':
                this.isExpDateValid = status;
                this.isExpDateTouched = true;
                break;
            default:
                console.log(field + " not supported", { field: field, status: status });
                break;
        }
        this.showErrorMessages = true;
    };
    return AddPaymentFormComponent;
}());
export { AddPaymentFormComponent };
